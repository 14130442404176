import React, { useState } from 'react';
import { Modal, Button, message } from 'antd'
import { JSONTree } from 'react-json-tree'
import frc from 'front-end-common';
import './styles.scss';
import DatabaseOutlined from '@ant-design/icons/lib/icons/DatabaseOutlined';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { validateGiftcode } from '../../Reducers/activations';


const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const useBoolean = frc.hooks.useBoolean;

function PopupWithJson({ code }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  const [jsonData, setJsonData] = useState()

  const dispatch = useDispatch();

  const handleOpenJsonModal = () => {
    open();
    dispatch(validateGiftcode(code))
    .then((res) => {
      // console.log('payload', res)
      if (res.decoded) {
        setJsonData(res)
      }
    })
    .catch((err) => {
      setJsonData("Error getting request")
      // console.log('err', err)
    })
  }

  async function copy() {
      await navigator.clipboard.writeText(JSON.stringify(jsonData))
  }

  return <>
    <Button disabled={!code} type="ghost" icon={!code ? <StopOutlined /> : <DatabaseOutlined/>} onClick={handleOpenJsonModal} />
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} centered style={{maxWidth: "500px"}} >
    <Button
                disabled={!jsonData}
                onClick={copy}
                className={'button'}
              >
                Copy
              </Button>
    <JSONTree invertTheme={true} hideRoot theme={theme} data={jsonData} />
    </Modal>
  </>;
}

export default PopupWithJson;
