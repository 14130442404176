import { useRoutes } from "react-router-dom";
import Accounts from "../Pages/Accounts";
import Account from "../Pages/Account";
import TransactionsHistory from "../Pages/TransactionsHistory";
import PurchaseJobs from "../Pages/PurchaseJobs";
import RedeemJobs from "../Pages/RedeemJobs";
import OtherJobs from "../Pages/OtherJobs";
import Archive from "../Pages/Archive";
import Config from "../Pages/Config";
import Proxies from "../Pages/Proxies";
import RedirectPage from "../Pages/RedirectPage";
import RedirectUserToMainPage from "../Components/RedirectUserToMainPage";
import RedirectGuestToSignInPage from "../Components/RedirectGuestToSignInPage";
import {
  UserOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  BarChartOutlined,
  BookOutlined,
  GlobalOutlined,
  LineChartOutlined,
  ReadOutlined,
  SettingOutlined,
  ShopOutlined,
  DesktopOutlined,
  FormOutlined,
  CompassOutlined,
  UserAddOutlined,

} from "@ant-design/icons"
import Settings from "../Pages/Settings";

function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage />,
    },
    {
      path: '/redirect',
      exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [
        {
          path: '/',
          exact: true,
          element: <TransactionsHistory />,
        },
        {
          path: '/settings',
          exact: true,
          element: <Settings />,
        },
        // {
        //   path: '/purchase-jobs',
        //   exact: true,
        //   element: <PurchaseJobs />,
        // },
        // {
        //   path: '/redeem-jobs',
        //   exact: true,
        //   element: <RedeemJobs />,
        // },
        // {
        //   path: '/other-jobs',
        //   exact: true,
        //   element: <OtherJobs />,
        // },
        // {
        //   path: '/archive',
        //   exact: true,
        //   element: <Archive />,
        // },
        // {
        //   path: '/config',
        //   exact: true,
        //   element: <Config />,
        // },
        // {
        //   path: '/accounts',
        //   exact: true,
        //   element: <Accounts />,
        // },
        // {
        //   path: '/account/:id',
        //   exact: true,
        //   element: <Account />,
        // },
        // {
        //   path: '/proxies',
        //   exact: true,
        //   element: <Proxies />,
        // },
      ],
    }
  ])
  return element;
}

export const links = [
  {
    href: '/',
    icon: <BarChartOutlined />,
    title: 'Transaction history',
  },
  {
    href: '/settings',
    icon: <SettingOutlined />,
    title: 'Settings',
  },
  // {
  //   href: '/accounts',
  //   icon: <UserOutlined />,
  //   title: 'Accounts',
  // },
  // {
  //   href: '/purchase-jobs',
  //   icon: <AccountBookOutlined />,
  //   title: 'Purchase Jobs',
  // },
  // {
  //   href: '/redeem-jobs',
  //   icon: <ReadOutlined />,
  //   title: 'Redeem Jobs',
  // },
  // {
  //   href: '/other-jobs',
  //   icon: <UnorderedListOutlined />,
  //   title: 'Other Jobs',
  // },
  // {
  //   href: '/archive',
  //   icon: <BookOutlined />,
  //   title: 'Archive',
  // },
  // {
  //   href: '/proxies',
  //   icon: <GlobalOutlined />,
  //   title: 'Proxies',
  // },
  // {
  //   href: '/config',
  //   icon: <ProfileOutlined />,
  //   title: 'Config',
  // },
]

export default Routes;
