import React, { useState, useCallback, useEffect } from 'react'
import { Table } from 'antd'
import b_ from 'b_'
// import TableSettings from "../../Pages/PurchaseJobs/TableSettings"
// import ImageViews from '../../Views/ImageViews'
// import LinksViews from '../../Views/LinksViews'
import ExpandViews from '../../Views/ExpandViews'
import PrintJobStatus from '../../Prints/PrintJobStatus';
import { JSONTree } from 'react-json-tree';
import theme from '../../../Utils/jsonTheme';
import useStorageState from '../../../Utils/useStorageState'
import Expandable from './Expandable'
import { Link } from 'react-router-dom'
import convertDate from '../../../Utils/convertDate'
import list from './mock.json'

const b = b_.lock('AccountsTable')

function getUpdatedTime(createTs, updateTs) {
  // Calculate the time difference in milliseconds
  const timeDiff = updateTs - createTs;

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeDiff / 60000);
  const seconds = Math.floor((timeDiff % 60000) / 1000);

  // Return the formatted time as a string
  return `${minutes}m ${seconds}s`;
}

function JobsTable({
    tableOnChange,
    onChange,
    pagination,
    loading,
    afterUpdate,
    onFilter,
    filters,
    selected,
    onSelectChange,
}) {
    const GetColumns = useCallback(() => {
        return [
            {
                dataIndex: "id",
                title: "ID",
                width: 52,
                sorter: (a, b) => a - b,
            },
            {
                dataIndex: "creationTime",
                title: "Created",
                width: 160,
                render: (creationTime) => convertDate(creationTime)
            },
            {
                dataIndex: "updateTime",
                title: "Updated",
                width: 90,
                render: (updateTime, rec) => <p>{getUpdatedTime(rec.creationTime, updateTime)}</p>,
            },
            {
                dataIndex: "assignedAccLogin",
                title: "Account",
                width: 190,
                render: (_, rec) => <Link to={`/account/${rec.accountId}`}>{rec.assignedAccLogin}</Link>
            },
            {
                dataIndex: "vmId",
                title: "Proxy",
                width: 74,
            },
            {
                dataIndex: "status",
                title: "Status",
                width: 92,
                // filteredValue: filters['status'] || null,
                filterMultiple: true,
                render: status => <PrintJobStatus jobStatus={status} />,
                sorter: (a, b) => a - b,
            },
            {
                dataIndex: "phase",
                title: "Phase",
                width: 86,
                sorter: (a, b) => a - b,
            },
            {
                dataIndex: "action",
                title: "Action",
                width: 435,
                render: json => <JSONTree hideRoot theme={theme} data={json} />,
            },
            {
                dataIndex: "result",
                title: "Result Code",
                width: 135,
                align: "center",
            },
            // {
            //     dataIndex: "screenshots",
            //     title: "Shots",
            //     width: 80,
            //     align: "center",
            //     render: images => <ImageViews disabled={!images.length} images={images} />,
            // },
            // {
            //     dataIndex: "pageSources",
            //     title: "Pages",
            //     width: 80,
            //     align: "center",
            //     render: pages => <LinksViews disabled={!pages.length} pages={pages} />,
            // },
            {
                dataIndex: "changes",
                title: "Changes",
                width: 125,
                align: "center",
                render: changes => <ExpandViews title="Changes" content={changes} />
            },
        ]
    }, [filters, list])

    const [columns, setColumns] = useState(GetColumns());

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('accounts_table', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
    }, [Checkboxs]);

    useEffect(() => {
        let allCheckList = []
        if (list.length && !Checkboxs.length) {
            GetColumns().forEach((e) => {
                allCheckList.push(e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }
    }, [list, AllCheckbox, Checkboxs.length, GetColumns])

    useEffect(() => {
        if (AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList: GetColumns().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumns())
    }, [list, loading, filters, GetColumns])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
    const loader = loading

    console.log('list', list)


    return <Table
        rowKey={(record) => `${record.id}`}
        className={b()}
        bordered
        dataSource={list.list || list}
        scroll={{ x: 'max-content' }}
        size="middle"
        onChange={onChange}
        onFilter={onFilter}
        pagination={pagination}
        loading={true}
        columns={columnsFilter}
        // title={() => <TableSettings
        //     onChangeCheckAll={onChangeCheckAll}
        //     allChecks={AllCheckbox}
        //     Checkboxs={AllCheckbox.checkedList}
        //     TableColumn={() => GetColumns()}
        //     setCheckbox={onChangeCheckList}
        // />}
        rowSelection={{
            selectedRowKeys: selected,
            onChange: onSelectChange,
        }}
        expandable={{
          expandedRowRender: record => <Expandable record={record} />,
        }}
    />
}

export default JobsTable;
