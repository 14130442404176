import { combineReducers } from "redux"
import auth from './auth'
import activations from './activations'
import settings from './settings'

export default combineReducers({
  auth,
  activations,
  settings
})
