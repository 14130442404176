import React, { useState } from 'react'
import TransactionsTable from '../../Components/TransactionsTable'
import { Input, Typography, message } from 'antd'
import listTest from './mock.json'
import TransactionsFilters from '../../Components/Filters/Transcations'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { activationsListFetch, assignList, assignWrk } from '../../Reducers/activations'
import useTable from '../../Utils/useTable'
import { useCallback } from 'react'
import PopupWithJson from '../../Components/PopupWithJson'

const { Search } = Input

const TransactionsHistory = () => {

  console.log(listTest)

  // const payload = useSelector(state => state.activations.list, shallowEqual);

  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.activations.list, activationsListFetch);

  const dispatch = useDispatch()


  // useEffect(() => {
  //   dispatch(activationsListFetch())
  // }, [])

  const transactions = list.list && list.list

  const { payload } = useSelector(state => state.activations.assignList, shallowEqual)

  useEffect(() => {
    dispatch(assignList())
  }, [])

  console.log('payload', payload)

  const handleAssignWorker = useCallback((giftcode) => {
    dispatch(assignWrk(giftcode))
      .then((res) => {
        // message.success()
        console.log('result', res)
        message.success(res)
        fetch()
      })
      .catch((err) => {
        console.log('err', err)
        message.error(err)
      })
  }, [])

  const [code, setCode] = useState()

  console.log('query', code)

  return (
    <div>
       <Typography.Title>Transactions History</Typography.Title>
       <TransactionsFilters assignlist={payload} filters={filters} onFilter={onFilter} afterUpdate={fetch} />
       <div>
        <Search allowClear addonBefore={<PopupWithJson code={code} />} defaultValue={query} placeholder="Search..." className="mb" size="large" onSearch={onSearch} onChange={(e) => setCode(e.target.value)} />
       
      </div>
      <TransactionsTable
        list={transactions}
        pagination={pagination}
        onChange={(a, b, c) => onChange(a, b, c)}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        assignWorker={handleAssignWorker}
      />
    </div>
  )
}

export default TransactionsHistory
