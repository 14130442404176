//@ts-nocheck
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from '../../Utils/cx';
import LoadingBlock from '../LoadingBlock';
import {
  fetchSettingsList,
  listSettingsFetchReset,
  settingsSchemaFetch,
  settingsUISchemaFetch,
  settingsUpdate,
  listSettingsUpdateReset,
} from '../../Reducers/settings';
import deepEqual from '../../Utils/deepEqual';
import useBoolean from '../../Utils/useBoolean';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import Ajv from 'ajv';
import './styles.scss'

import mockData from './mockData.json'
import mockSchema from './mockSchema.json'
import mockUISchema from './mockUiSchema.json'

const { Title } = Typography;

function SteamSettings() {
  const dispatch = useDispatch();

  const { value: isVisibleSave, setFalse: setVisibleSave, setTrue: setUnVisibleSave } = useBoolean(true);

  const [stateSettings, setStateSettings] = useState({})
  const [schema, setSchema] = useState({})
  const [uiSchema, setUISchema] = useState({})

  useEffect(() => {
    dispatch(fetchSettingsList());
    dispatch(settingsSchemaFetch());
    dispatch(settingsUISchemaFetch());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { settings, updateSettingsLoading, settingsSchema, settingsUISchema } = useSelector(
    (state) => ({
      settings: state.settings?.list,
      updateSettingsLoading: state.settings?.settingsUpdate?.isLoading,
      settingsSchema: state.settings?.schema,
      settingsUISchema: state.settings?.uiSchema,
    }),
    shallowEqual,
  );

  console.log('dataPayload', settings)
  console.log('settingsPayload', settingsSchema)
  console.log('uiSettingsPayload', settingsUISchema)

  const { dataPayload, settingsPayload, uiSettingsPayload } = {
    dataPayload: settings?.payload,
    settingsPayload: settingsSchema?.payload,
    uiSettingsPayload: settingsUISchema?.payload
  }

  console.log('dataPayload', dataPayload)
  console.log('settingsPayload', settingsPayload)
  console.log('uiSettingsPayload', uiSettingsPayload)

  useEffect(() => {
    if (settings?.isLoading || (settings?.payload && deepEqual(stateSettings, settings?.payload))) {
      setUnVisibleSave();
      return;
    }
    setVisibleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSettings, settings]);

  useEffect(() => {
    // if(settingsSchema?.payload) {
      if(settingsPayload) {
      // const obj = {
      //   ...schema,
      //   type: settingsSchema?.payload.type,
      //   properties: settingsSchema?.payload.properties,
      //   required: settingsSchema?.payload.required,
      //   definitions: settingsSchema?.payload.definitions,
      // }
      setSchema(settingsPayload)
    }
  }, [settingsPayload])

  useEffect(() => {
    // if(settingsUISchema?.payload) {
      if (uiSettingsPayload) {
      // const obj = {
      //   ...uiSchema,
      //   type: settingsUISchema?.payload.type,
      //   elements: settingsUISchema?.payload.elements
      // }
      setUISchema(uiSettingsPayload)
    }
  }, [uiSettingsPayload])
  
  useEffect(() => {
    // if (settings?.payload) {
    //   if (dataPayload) {
    //   // const obj = {
    //   //   ...stateSettings,
    //   //   g2aOwnSellers: settings?.payload.g2aOwnSellers,
    //   //   g2aTradeSettings: settings?.payload.g2aTradeSettings,
    //   //   gamivoOwnSellers: settings?.payload.gamivoOwnSellers,
    //   //   gamivoRivalCriteria: settings?.payload.gamivoRivalCriteria,
    //   //   gamivoSandboxProducts: settings?.payload.gamivoSandboxProducts,
    //   //   gamivoTradeSettings: settings?.payload.gamivoTradeSettings,
    //   //   resellTradeSettings: settings?.payload.resellTradeSettings,
    //   //   steamGamivoAutotradeSettings: settings?.payload.steamGamivoAutotradeSettings,
    //   //   steamGamivoComissions: settings?.payload.steamGamivoComissions,
    //   //   battlekeysAccountEnabledOnG2A: settings?.payload.battlekeysAccountEnabledOnG2A,
    //   //   battlekeysAccountEnabledOnGamivo: settings?.payload.battlekeysAccountEnabledOnGamivo,
    //   //   battlekeysPriceMultiplier: settings?.payload.battlekeysPriceMultiplier,
    //   //   gamesmarAccountEnabledOnG2A: settings?.payload.gamesmarAccountEnabledOnG2A,
    //   //   gamesmarAccountEnabledOnGamivo: settings?.payload.gamesmarAccountEnabledOnGamivo,
    //   //   ggselTradeSettings: settings?.payload.ggselTradeSettings,
    //   // };
    //   setStateSettings(dataPayload)
    // }
    setStateSettings(dataPayload)
  }, [dataPayload]); // eslint-disable-line react-hooks/exhaustive-deps

  function compareObjects(obj1, obj2) {
    let arr = []
    for (const key in obj1) {
      if(JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
          arr.push({name: key, value: JSON.stringify(obj2[key])})
        }
    }
    return arr
  }

  const ajv = new Ajv();

  // function clearSchema () {
  //   return _.omit(schema, ['payload', 'isLoading', 'meta', 'error'])
    
  // }

  // console.log('schemapayload', schema?.payload)
  // console.log('settingpayload', settings.payload)

  const handleSave = useCallback((e) => {
    // const copy = normalizeData(stateSettings)
    const copy = {...stateSettings}
    // transform into { 'name': 'string', 'value': 'string' }
    // delete dataPayload['steamDiscountExpired.min']
    const copiedData = compareObjects(dataPayload, copy)
    // console.log('COPY', copy)

    const validate = ajv.compile(schema)
    const valid = validate(copy)
    if(!valid) {
      message.error('Please fill all data correctly')
    } else {
    dispatch(settingsUpdate(copiedData))
      .then(() => message.success('Success update Dashboard settings'))
      .catch((e) => message.error(`Error update Dashboard settings: ${e}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSettings]);

  useEffect(
    () => () => {
      dispatch(listSettingsFetchReset());
      dispatch(listSettingsUpdateReset());
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // console.log('SETTINGS', stateSettings)
  // console.log('SCHEMA', schema)
  // console.log('MOCK', mockSchema)

  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();

    const inputFields = document.querySelectorAll('input[type="number"]');

    inputFields.forEach((inputField) => {
      inputField.addEventListener('wheel', handleWheel);

      return () => {
        inputField.removeEventListener('wheel', handleWheel);
      };
    });
  });
  

  return (
    <Fragment>
      <Title className={cx("title", "mb")}>
        Settings
        <Button type="primary" onClick={(e) => handleSave(e)} disabled={isVisibleSave} loading={updateSettingsLoading}>
            Save
        </Button>
      </Title>
      <LoadingBlock loading={settings?.isLoading}>
      <div>
        <JsonForms
          schema={schema}
          uischema={uiSchema}
          data={stateSettings}
          // schema={mockSchema}
          // uischema={mockUISchema}
          // data={mockData}
          cells={materialCells}
          renderers={materialRenderers}
          validationMode={'ValidateAndShow'}
          onChange={({errors, data}) => setStateSettings(data)}
            />
      </div>
      </LoadingBlock>
      </Fragment>
  );
}

export default SteamSettings;
