import { useCallback, useState } from 'react';

export default function useBoolean(initial) {
  const [value, setValue] = useState(initial);

  return {
    value,
    setValue,
    toggleValue: useCallback(() => setValue((v) => !v), []),
    setTrue: useCallback(() => setValue(true), []),
    setFalse: useCallback(() => setValue(false), []),
  };
}
