import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input, Button } from 'antd'
// import useTable from '../../Utils/useTable'
// import { cancelPurchaseJobs, purchaseJobsListFetch } from '../../Reducers/purchaseJobs'
// import JobFilters from '../../Components/JobFilters';
import {CloseCircleOutlined } from '@ant-design/icons';
// import CancelJobButton from '../../Components/CancelJobButton'
import JobsTable from '../../Components/Tables/Jobs'

const { Search } = Input

function PurchaseJobs() {
  // const {
  //   list,
  //   pagination,
  //   onChange,
  //   onFilter,
  //   isLoading,
  //   fetch,
  //   filters,
  //   error,
  //   query,
  //   onSearch
  // } = useTable(state => state.purchaseJobs.list, purchaseJobsListFetch);

  // const [selectedRow, setSelectedRow] = useState([]);

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  return (
    <>
      <Typography.Title>Purchase Jobs</Typography.Title>
      {/* <div>
        <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} />
      </div> */}
      {/* <JobFilters filters={filters} onFilter={onFilter} className="mb"/>
      <CancelJobButton isLoading={isLoading} selectedRow={selectedRow} fetch={fetch} setSelectedRow={setSelectedRow} /> */}
      <JobsTable
        // list={list}
        // tableOnChange={(a, b, c) => onChange(a, b, c)}
        // onChange={onChange}
        // pagination={pagination}
        // loading={isLoading}
        // afterUpdate={fetch}
        // onFilter={onFilter}
        // filters={filters}
        // selected={selectedRow}
        // onSelectChange={onSelectChange}
      />
    </>
  );
}

export default PurchaseJobs;
