import { Button, message } from 'antd'
import React, { useCallback } from 'react'
import './styles.scss';
import { useDispatch } from 'react-redux';
import { reset, sendAgain, setDoneManually } from '../../Reducers/activations';

const Administration = ({ phase, giftCode, jobId, status, afterUpdate}) => {

  const allowedPhases = [ "Prepare", "Purchase", "WaitGiftAccept", "Cancel"]

  const dispatch = useDispatch()

  const handleResend = (giftcode) => {
    dispatch(sendAgain(giftcode))
      .then((res) => {
        message.success('Successfully resent gift!')
      })
      .catch((err) => {
        message.error(`Failed to resend gift: ${err}`)
      })
  }

  const handleReset = (giftcode) => {
    dispatch(reset(giftcode))
    .then((res) => {
      message.success('Successfully reset link!')
    })
    .catch((err) => {
      message.error(`Failed to reset link: ${err}`)
    })
  }

  const handleSetDone = useCallback((giftcode) => {
    dispatch(setDoneManually(giftcode))
    .then((res) => {
      message.success('Successfully set job done!')
      afterUpdate()
    })
    .catch((err) => {
      message.error(`Failed to set done: ${err}`)
    })
  }, [afterUpdate, dispatch])

  return (
    <div className='Administration'>
      <Button type="primary" onClick={() => handleResend(giftCode)}>Send Again</Button>
      <Button type="primary" danger onClick={() => handleReset(giftCode)}>Clear link</Button>
      {jobId && status === "Error" && allowedPhases.includes(phase) && <Button type="primary" className="button_green" onClick={() => handleSetDone(giftCode)}>Set done</Button>}
    </div>
  )
}

export default Administration
