import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
// import { changeProxies, proxiesListFetch } from '../../Reducers/proxies'
import ProxiesTable from '../../Components/Tables/Proxies'
import NewButton from './GroupEditing/NewButton'
import GroupEnablingButtonGroup from './GroupEditing/GroupEnablingButtonGroup'
// import { fetchAccountsGroups } from '../../Reducers/accounts'
import list from './mock.json'

const { Search } = Input

function Proxies() {
  // const {
  //   list,
  //   pagination,
  //   onChange,
  //   onFilter,
  //   isLoading,
  //   fetch,
  //   filters,
  //   error,
  //   query,
  //   onSearch
  // } = useTable(state => state.proxies.list, proxiesListFetch);

  const [selectedRow, setSelectedRow] = useState([]);

  const fieldsChange = [
    "id",
    "proxyType",
    "host",
    "port",
    "status",
    "comment",
    "group",
    "accounts"
]

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  const [payload, setPayload] = useState([]);

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const payload = selectedRows.map((row) => ({
        id: row.id,
        proxyType: row.proxyType,
        host: row.host,
        port: row.port,
        comment: row.comment,
        group: row.group,
        accounts: row.accounts
    }));
  
    setSelectedRow(selectedRowKeys);
    setPayload(payload);
  };

  const dispatch = useDispatch()

//   const updateProxies = useCallback((params) => {
//     dispatch(changeProxies(params)).then((e) => {
//         try {
//             message.success('Proxies completed update.');
//             fetch()
//         }catch (e) {
//             message.error(`Can't update Proxies: ${e}`);
//             fetch()
//         }
//     })
// }, [dispatch, fetch]);

// const groups = useSelector(state => state.accounts.accountsGroups.payload, shallowEqual);

  // useEffect(() => {
  //     !groups && dispatch(fetchAccountsGroups());
  // },[dispatch, groups])

  return (
    <>
      <Typography.Title>Proxies</Typography.Title>

      <div className="buttonsGroup">
        {/* <GroupEnablingButtonGroup selected={selectedRow} afterUpdate={fetch} payload={payload} /> */}
        {/* <NewButton selected={selectedRow} afterUpdate={fetch} groups={groups} /> */}
        
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
        {/* <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} /> */}
      <ProxiesTable
        list={list}
        // tableOnChange={(a, b, c) => onChange(a, b, c)}
        // pagination={pagination}
        // loading={isLoading}
        afterUpdate={fetch}
        // onFilter={onFilter}
        // filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        // handleSave={updateProxies}
        fieldsChange={fieldsChange}
      />
    </>
  );
}

export default Proxies;
