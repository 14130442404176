import React from 'react';
import b_ from 'b_';
import AuthLayout from '../../Components/AuthLayout';
import SingInForm from '../../Components/SignInForm';
import './styles.scss';

const b = b_.lock('SignIn');

function SignIn() {
  return (
    <AuthLayout className={b()}>
      <div className={b('card')}>
        <SingInForm />
      </div>
    </AuthLayout>
  );
}

export default SignIn;
