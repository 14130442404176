import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import b_ from "b_";
import './styles.scss';
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { history } from "../../../Boot/createStore";
import { links } from "../../../Boot/Routes";
import Logout from '../../Logout';
import fec from 'front-end-common';

const b = b_.lock('Header')

const AntHeader = Layout.Header;
const { SubMenu } = Menu;
const { Sider } = Layout;

const IconType = ({ state }) => {
  return (
    <Button type="primary">
      {state ? <MenuUnfoldOutlined style={{ fontSize: 20 }} /> : <MenuFoldOutlined style={{ fontSize: 20 }} />}
    </Button>
  )
}

function Header({ className }) {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const [icon, setIcon] = useState(false)
  const path = links.filter((link) => history.location.pathname === link.href);

  const platforms = fec.utils.platformsData;

  return (
    <AntHeader className={`${b()} ${className}`} router={{ location, navigate, params }} >
      <Sider width="63px" className="Menu">
        <Menu
          mode="vertical"
          forceSubMenuRender={false}
          defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          defaultOpenKeys={(path.length && [path[0].href]) || ''}
          onOpenChange={() => setIcon(!icon)}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{ fontSize: 15 }}
            title={<IconType state={icon} />}
          >
            {links.map((link) => (
              <Menu.Item key={link.href}  >
                <Link to={link.href} >
                  {link.icon}
                  <span>{link.title}</span>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Sider>
      <Menu
        defaultSelectedKeys={['steam-acti']}
        mode="horizontal"
        className="Nav"
      >
         
         <Menu.Item key='steam-acti'>
          <a href={'https://steam-activation.hoteos.net'}>Steam Activation</a>
        </Menu.Item>
      </Menu>
      <div className="Logout">
        <Logout />
      </div>
    </AntHeader>
  );
}

export default React.memo(Header);
