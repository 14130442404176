import React from 'react';
import { Tag } from 'antd';

const JOB_STATUS_TO_COLOR = {
  New: 'blue',
  Done: 'green',
  Error: 'red',
  OK: 'green',
  Verify: 'green'
};

function PrintStatus({ className, jobStatus }) {
  return <Tag className={className} color={JOB_STATUS_TO_COLOR[jobStatus]}>
    {jobStatus?.toLowerCase()}
  </Tag>;
}

export default React.memo(PrintStatus);
