import React from 'react'
import convertDate from '../../../Utils/convertDate'
import './styles.scss'

const PrintDates = ({ createTime, updateTime }) => {
  return (
    <div className="TableDate">{convertDate(createTime)} <br/> {convertDate(updateTime)}</div>
  )
}

export default PrintDates
