import { configureStore, createStore } from "@reduxjs/toolkit"
import { applyMiddleware } from "redux";
import frc from 'front-end-common';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import rootReducer from '../Reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

const utilMiddleware = frc.utils.reducerTools.middleware;

export const history = createBrowserHistory();

function store(initialState = {}) {
  let middleware = applyMiddleware(utilMiddleware, routerMiddleware(history))

  return createStore(rootReducer, initialState, composeWithDevTools(middleware))
};

export default store
