import { Table, Tag, Tooltip } from 'antd'
import React from 'react'
import { useCallback } from 'react'
import b_ from 'b_'
import { useState } from 'react'
import PrintStatus from '../PrintStatus'
import PrintResult from '../PrintResult'
import { Link } from 'react-router-dom'
import Administration from '../Administration'
import convertDate from '../../Utils/convertDate'
import { useEffect } from 'react'
import PrintDates from '../Prints/PrintDates'

const b = b_.lock('TransactionsTable')

const TransactionsTable = ({ 
  list,
  pagination,
  onChange,
  loading,
  onFilter,
  filters,
  afterUpdate,
  assignWorker
}) => {

  const GetColumns = useCallback(() => {
    return [
        {
            dataIndex: "jobId",
            title: "ID Purch Job",
            width: 122,
            render: (jobId) => <b>{jobId}</b>
        },
        {
            dataIndex: "giftCode",
            title: "Unique Code",
            width: 120,
            align: "center",
            render: (giftCode) => <Link to={`https://acti.battlekeys.com/#/giftcode/${giftCode}`} target="_blank" rel="noopener noreferrer">{giftCode}</Link>
        },
        {
            dataIndex: "response",
            title: "Package",
            width: 230,
            render: (response) => <span>[{response?.game?.region?.toUpperCase()}] {response?.game?.name}</span>
        },
        {
            dataIndex: "response",
            title: "Status",
            width: 90,
            render: response => <PrintStatus jobStatus={response?.transferStatus} />,
        },
        {
            dataIndex: "manager",
            title: "Assigned",
            align: "center",
            width: 90,
            render: (manager, rec) => manager ? <Tag color="green">{manager.mail}</Tag> : <Tooltip title='Click to assign worker'><Tag onClick={() => assignWorker(rec.giftCode)}>none</Tag></Tooltip>,
        },
        {
            dataIndex: "response",
            title: "Region",
            width: 92,
            render: response => <i>{response?.country?.name}</i>,
        },
        {
          dataIndex: "response",
          title: "Customer",
          width: 120,
          render: (response) => <Link to={`https://steamcommunity.com/profiles/${response?.user?.id}`} target="_blank" rel="noopener noreferrer">{response?.user?.name}</Link>
      },
        {
            dataIndex: "result",
            title: "Phase",
            width: 86,
            render: (result, rec) => result === "OK" ? <PrintResult result={rec?.phase} /> : <PrintResult result={rec?.response?.phase} />,
        },
        {
            dataIndex: "result",
            title: "Result Code",
            width: 105,
            align: "center",
            render: result => <PrintResult result={result} />,
        },
        {
            dataIndex: "createTs",
            title: "Date & Time",
            width: 185,
            align: "center",
            render: (createTs, rec) => <PrintDates createTime={createTs} updateTime={rec.updateTs} />
        },
        {
            dataIndex: "giftCode",
            title: "Administration",
            width: 200,
            align: "center",
            render: (giftCode, rec) => <Administration  phase={rec?.response?.phase} jobId={rec?.jobId} status={rec?.response?.transferStatus} giftCode={giftCode} afterUpdate={afterUpdate} />
        },
    ]
}, [])

const [columns, setColumns] = useState(GetColumns());

useEffect(() => {
  setColumns(GetColumns())
}, [list, loading, filters, GetColumns])

  return (
    <Table
        rowKey={(record) => `${record.giftCode}`}
        className={b()}
        bordered
        dataSource={list}
        scroll={{ y: "calc(100vh - 125px)", x: 'max-content' }}
        size="middle"
        onChange={onChange}
        onFilter={onFilter}
        pagination={pagination}
        loading={loading}
        columns={columns}
    />
  )
}

export default TransactionsTable
