import { Typography, Modal, Button } from 'antd'
import { JSONTree } from 'react-json-tree'
import theme from '../../../Utils/jsonTheme'
import frc from 'front-end-common';
import './styles.scss';

const { Paragraph } = Typography
const useBoolean = frc.hooks.useBoolean;


function ExpandViews({ title, content }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  return <>
    <Button
      disabled={!content}
      type="primary"
      onClick={open}
    >
      {title}
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered style={{maxWidth: "500px"}} >
      {
        Array.isArray(content)
          ? content.map((item) => <Paragraph copyable key={item}><JSONTree hideRoot theme={theme} data={item} /></Paragraph>)
          : <Paragraph copyable><JSONTree hideRoot theme={theme} data={content} /></Paragraph>
      }
    </Modal>
  </>;
}

export default ExpandViews;


