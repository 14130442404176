import React, { useCallback, useEffect } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { List } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import ErrorBlock from '../../Components/ErrorBlock';
// import { fetchConfigs } from '../../Reducers/config';
import ConfigItem from './ConfigItem';
import './styles.scss';
import list from './mock.json'

const b = b_.lock('Config');

function Config() {

  const data = { isLoading: false, payload: list}
  const update = { isLoading: false }
  // const { data, update } = useSelector(state => state.config, shallowEqual);
  // const dispatch = useDispatch();

  // const fetch = useCallback(() => {
  //   dispatch(fetchConfigs());
  // }, [dispatch]);

  // useEffect(() => {
  //   fetch();
  // }, [fetch]);

  return <>
    <Title className="mb">Config</Title>
    {/* {data.error && <ErrorBlock className="mb" message={data.error.message} />} */}
    <List 
    // loading={data.isLoading || update.isLoading}
          loading={true}
          dataSource={data.payload || []}
          bordered
          className={b('list')}
          renderItem={item => (
            <List.Item className={b('listItem')}>
              <ConfigItem fetch={fetch} item={item} />
            </List.Item>
          )}
    />
  </>;
}

export default Config;
