import ApiService from "./ApiService";
import { core } from './Constants';

const ActivationService = {
  getActivationsList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get(`${core}/mgr/activations`, updatedParams);
  },

  getAssignList() {
    return ApiService.get(`${core}/mgr/assignlist`)
  },

  reset(giftcode) {
    return ApiService.delete(`${core}/mgr/activation/${giftcode}/reset`)
  },

  sendAgain(giftcode) {
    return ApiService.patch(`${core}/mgr/activation/${giftcode}/sendagain`)
  },

  assign(giftcode) {
    return ApiService.patch(`${core}/mgr/activation/${giftcode}/assign`)
  },

  validateGiftcodeInfo(giftcode) {
    return ApiService.get(`${core}/mgr/activation/${giftcode}/info`)
  },

  setManualOk(giftcode) {
    return ApiService.patch(`${core}/mgr/activation/${giftcode}/setmanualok`)
  }


}

export default ActivationService
