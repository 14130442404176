import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import SettingsService from '../Services/SettingsService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchSettingsList = createAction('settings/list', () => ({
  payload: SettingsService.fetchSettings(),
}))

export const listSettingsFetchReset = createAction('settingsFetchReset')

export const settingsSchemaFetch = createAction('settings/schema', () => ({
  payload: SettingsService.fetchSettingsSchema()
}))

export const settingsUISchemaFetch = createAction('settings/ui-schema', (giftcode) => ({
  payload: SettingsService.fetchSettingsUISchema()
}))

export const settingsUpdate = createAction('settings/update', (giftcode) => ({
  payload: SettingsService.updateSettings(giftcode)
}))

export const listSettingsUpdateReset = createAction('settingsUpdateReset')

const initState = {
  list: getDefaultState(),
  schema: getDefaultState(),
  uiSchema: getDefaultState(),
  update: getDefaultState(),
  settingsFetchReset: getDefaultState(),
  settingsUpdateReset: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchSettingsList, 'list'),
  ...getDefaultHandler(settingsSchemaFetch, 'schema'),
  ...getDefaultHandler(settingsUISchemaFetch, 'uiSchema'),
  ...getDefaultHandler(settingsUpdate, 'update'),
  ...getDefaultHandler(listSettingsFetchReset, 'settingsFetchReset'),
  ...getDefaultHandler(listSettingsUpdateReset, 'settingsUpdateReset')
});
