import { Title } from '@mui/icons-material'
import React, { useEffect } from 'react'
import SteamSettings from '../../Components/Settings'

const Settings = () => {

  return (
    <div>
      <SteamSettings />
    </div>
  )
}

export default Settings
