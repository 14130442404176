import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Select} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import b_ from 'b_';
// import { createProxies } from '../../../Reducers/proxies';
import TextArea from 'antd/lib/input/TextArea';
// import { changeRatios } from '../../../Reducers/resell';

const b = b_.lock('NewButton');

function CreateProxy({ className, afterUpdate, groups }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const [proxy, setProxy] = useState("");


  // const handleSuccess = useCallback((data) => {
  //   Object.keys(data).map(e => !data[e] ? data[e] = "" : null)
  //   dispatch(createProxies([{...data, accounts: []}]))
  //   .then(() => {
  //     close();
  //     afterUpdate();
  //     message.success('Proxy has been added!');
  //   })
  //   .catch((error) => {
  //     message.error(`Failed to create proxy: ${error}`)
  //   });
  // }, [close, afterUpdate, dispatch]);

  return (
    <>
      <Button
          type="primary"
          size="medium"
          className={className}
          icon={<PlusOutlined />}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Proxy" width={600} closable placement="right" onClose={close}>
        {/* {error && <ErrorBlock message={error.message} />} */}
        <Form layout="vertical" className={b()} 
        // onFinish={handleSuccess}
        >
        <Form.Item
              label="Group:"
              name="group"
          >
              <Select>
                  {groups && groups.map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
            label="Type:"
            name="proxyType"
          >
              <Select onChange={(e) => setProxy(e)}>
                  {["http", "socks5"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
            rules={[
              proxy === "socks5" && { required: true, message: 'Please input host.' },
            ]}
            label="Host:"
            name="host"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
                proxy === "socks5" ?{ required: true, message: 'Please input port.' } : {required: false},
            ]}
            label="Port:"
            name="port"
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          {/* <Form.Item
            label="User:"
            name="user"
          >
            <Input type="text"/>
          </Form.Item>
          <Form.Item
            label="Password:"
            name="password"
          >
              <Input type="text"/>
          </Form.Item> */}
          <Form.Item
            label="Status:"
            name="status"
          >
              <Select>
                  {["Enabled", "Disabled"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                icon={isLoading && <LoadingOutlined />}
                htmlType="submit"
                >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateProxy
