import { createAction, createReducer } from '@reduxjs/toolkit';
import AuthService from '../Services/AuthService';
import TokenService from '../Services/TokenService';
import frc from 'front-end-common';
import ActivationService from '../Services/ActivationService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const activationsListFetch = createAction('activations/list', (params) => ({
  payload: ActivationService.getActivationsList(params),
}))

export const assignList = createAction('activations/assign-list', () => ({
  payload: ActivationService.getAssignList()
}))

export const reset = createAction('activations/reset', (giftcode) => ({
  payload: ActivationService.reset(giftcode)
}))

export const sendAgain = createAction('activations/send', (giftcode) => ({
  payload: ActivationService.sendAgain(giftcode)
}))

export const assignWrk = createAction('activations/assign', (giftcode) => ({
  payload: ActivationService.assign(giftcode)
}))

export const validateGiftcode = createAction('activations/validate', (giftcode) => ({
  payload: ActivationService.validateGiftcodeInfo(giftcode)
}))

export const setDoneManually = createAction('activations/set-manual', (giftcode) => ({
  payload: ActivationService.setManualOk(giftcode)
}))

const initState = {
  list: getDefaultState(),
  assignList: getDefaultState(),
  reset: getDefaultState(),
  send: getDefaultState(),
  assign: getDefaultState(),
  validate: getDefaultState(),
  setManual: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(activationsListFetch, 'list'),
  ...getDefaultHandler(assignList, 'assignList'),
  ...getDefaultHandler(reset, 'reset'),
  ...getDefaultHandler(sendAgain, 'send'),
  ...getDefaultHandler(assignWrk, 'assign'),
  ...getDefaultHandler(validateGiftcode, 'validate'),
  ...getDefaultHandler(setDoneManually, 'setManual'),
});
