import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { parseQueryString, prepareQueryString } from '../Services/ApiService';
const EMPTY_FILTERS = {};

const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100', '200', '500'];

function buildUseTable(useDispatch, useSelector, shallowEqual, useLocation, useNavigate) {
  return function (selector, fetchAction) {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {
      payload,
      isLoading,
      error,
    } = useSelector(selector, shallowEqual);

    const { list, total, info } = useMemo(() => ({
      list: (payload && payload) || [],
      info: (payload && payload.info) || [],
      total: (payload && payload.total) || 0,
    }), [payload]);

    const params = useMemo(
      () => ({ limit: 50, offset: 0, ...parseQueryString(location.search) }),
      [location.search],
    );

    const fetch = useCallback(() => {
      dispatch(fetchAction(params));
    }, [params, dispatch, fetchAction]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    const onChange = useCallback(({ pageSize, current }, newTableFilters, { field, order }) => {
      const limit = pageSize;
      let offset = (current - 1) * pageSize;

      if (params.offset === offset) {
        offset = 0;
      }

      const orderBy = (field && order) ? field : null;
      const orderPath = (orderBy && order) || null;

      const curFilters = { ...filters, ...newTableFilters };
      const newFilters = Object.keys(curFilters)
        .filter(key => curFilters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {});

      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          limit,
          filters: newFilters,
          offset,
          orderBy,
          orderPath,
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, params, navigate]);

    const onFilter = useCallback((newTableFilters) => {
      const curFilters = { ...filters, ...newTableFilters };
      const newFilters = Object.keys(curFilters)
        .filter(key => curFilters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {});

      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          filters: newFilters,
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, params, navigate]);

    const onSearch = useCallback((query = '') => {
      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          q: query.trim() ? query : undefined,
        }),
      });
    }, [navigate, location.pathname, params]);

    const filters = useMemo(() => params.filters || EMPTY_FILTERS, [params.filters]);
    const query = useMemo(() => params.query || '', [params.query]);

    const pagination = useMemo(() => ({
      total,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
      current: Math.round((params.offset || 0) / (params.limit || 50) + 1),
      pageSize: parseInt(params.limit, 10) || 50,
    }), [params.offset, params.limit, total]);

    const { code, states } = useMemo(() => {
      return {
        code: (payload && payload.code) || {},
        states: (payload && payload.states) || [],
      };
    }, [payload]);

    const { resultCodes } = useMemo(() => {
      return {
        resultCodes: (payload && payload.resultCodes) || [],
      };
    }, [payload]);

    return {
      error,
      list,
      info,
      isLoading,
      pagination,
      onChange,
      filters,
      onSearch,
      query,
      fetch,
      onFilter,
      states,
      code,
      resultCodes
    };
  }
}

export default buildUseTable(useDispatch, useSelector, shallowEqual, useLocation, useNavigate);
