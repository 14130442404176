import React from 'react';
import { Tag } from 'antd';

// const JOB_STATUS_TO_COLOR = {
//   New: 'blue',
//   Done: 'green',
//   Error: 'red',
//   OK: 'green',
//   Verify: 'green'
// };

function PrintResult({ className, result, isPhase }) {
  return (
  result === "OK" || result === "Verify" ?  <Tag className={className} color={'green'}>
    {result}
  </Tag>
  :
  result
  )
}

export default React.memo(PrintResult);
