import React from 'react';
import cx from '../../Utils/cx';
import './styles.scss';

function AuthLayout({ children, className }) {
  return (
    <div className='authLayout'>
      <div className={cx('__inner', className)}>{children}</div>
    </div>
  );
}

export default AuthLayout;
