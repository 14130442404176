import ApiService from "./ApiService";
import { steamPrices } from "./Constants";

const SettingsService = {
  fetchSettings() {
    return ApiService.get(`${steamPrices}/configs`);
  },

  fetchSettingsSchema() {
    return ApiService.get(`${steamPrices}/configs/schema`)
  },
  fetchSettingsUISchema() {
    return ApiService.get(`${steamPrices}/configs/uischema`)
  },
  updateSettings(data) {
    return ApiService.put(`${steamPrices}/configs`, data);
  },

}

export default SettingsService
