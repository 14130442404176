import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Col, Button, Input, Row, Select } from 'antd';
import './styles.scss';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';

const { Option } = Select;

const b = b_.lock('TransactionsFilters');

function TransactionsFilters({ filters, onFilter, afterUpdate, assignlist }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  const handleResetFilters = useCallback(() => {
    for (var prop in filters) delete filters[prop];
    setCurFilters([])
    onFilter()
  }, [filters, onFilter])

  console.log('curFilters', curFilters)

  return (
    <>
  <Row justify={"flex-start"}>
    
    <Col style={{marginRight: '8px'}}>
      <span className={b('label')}>Select assigned</span>

      <Select
      value={curFilters.assigned ? curFilters.assigned[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'assigned')}
    >
      {assignlist?.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
    </Select>
    </Col>

  <Col>
      <span className={b('label')}>Status</span>

      <Select
      value={curFilters.status ? curFilters.status[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'status')}
    >
      <Option value="Error">Error</Option>
      <Option value="Done">Done</Option>
      <Option value="New">New</Option>
    </Select>
    </Col>
  
</Row>
<Row gutter={8} style={{marginBottom: '16px'}}>
<Col>

<span className={b('label')} />
    <Button
      type="primary"
      onClick={handleResetFilters}
      className={b('item')}
      icon={<RedoOutlined />}
    >
      Reset Filters
    </Button>
    </Col>
    <Col >
    <span className={b('label')} />
    <Button
      type="primary"
      onClick={() => onFilter(curFilters)}
      className={b('item')}
      icon={<FilterOutlined />}
    >
      Filter
    </Button>
    
    </Col>
    </Row>
</>
  )
}

export default TransactionsFilters;
